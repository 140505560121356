<!--
  ~ Ki-Tax: System for the management of external childcare subsidies
  ~ Copyright (C) 2017 City of Bern Switzerland
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as
  ~ published by the Free Software Foundation, either version 3 of the
  ~ License, or (at your option) any later version.
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. If not, see <http://www.gnu.org/licenses/>.
  -->

<div class="testdaten-view testdatenModul">
    <div class="row">
        <div class="col-md-8 col-md-offset-2">
            <div class="view-title margin-top-10">
                <h2 [textContent]="'TESTFAELLE_EINFUEGEN' | translate"></h2>
            </div>

            <!--selecton fuer parameter die bestimmen wie die testfaelle erzeugt werden-->
            <div class="row margin-top-10">
                <div class="col-sm-3">
                    <mat-radio-group
                        name="creationType"
                        [(ngModel)]="creationType"
                        required
                    >
                        <mat-radio-button
                            data-test="creationType.warten"
                            value="warten"
                            >Betreuungen mit Zustand
                            &#034;warten&#034;</mat-radio-button
                        >
                        <mat-radio-button
                            data-test="creationType.bestaetigt"
                            value="bestaetigt"
                            >Betreuungen mit Zustand &#034;bestätigt&#034;
                        </mat-radio-button>
                        <mat-radio-button
                            data-test="creationType.verfuegt"
                            value="verfuegt"
                            >Verfügte Erstgesuche</mat-radio-button
                        >
                    </mat-radio-group>
                </div>
                <div class="col-sm-3">
                    <label class="md-no-float" for="besitzer">
                        Gesuchsteller wenn gewünscht
                    </label>
                    <mat-select
                        [(ngModel)]="selectedBesitzer"
                        id="besitzer"
                        name="besitzer"
                        class="input-testfall"
                        data-test="gesuchsteller"
                    >
                        <mat-option
                            *ngFor="let gs of gesuchstellerList"
                            [value]="gs"
                            [attr.data-test]="getGesuchstellerDataTestValue(gs)"
                        >
                            {{ gs.vorname }} {{ gs.nachname }}
                        </mat-option>
                    </mat-select>
                </div>
                <div class="col-sm-3">
                    <label class="md-no-float" for="periode"> Gemeinde </label>
                    <mat-select
                        [(ngModel)]="selectedGemeinde"
                        id="gemeinde"
                        name="gemeinde"
                        class="input-testfall"
                        data-test="gemeinde"
                    >
                        <mat-option
                            *ngFor="let gem of gemeindeList"
                            [value]="gem"
                            [attr.data-test]="'gemeinde.' + gem.name"
                        >
                            {{ gem.name }}
                        </mat-option>
                    </mat-select>
                </div>
                <div class="col-sm-3">
                    <label class="md-no-float" for="periode">
                        Gesuchsperiode
                    </label>
                    <mat-select
                        [(ngModel)]="selectedGesuchsperiode"
                        id="periode"
                        name="periode"
                        class="input-testfall"
                        data-test="periode"
                    >
                        <mat-option
                            *ngFor="let gp of gesuchsperiodeList"
                            [value]="gp"
                            [attr.data-test]="
                                'periode.' + gp.gesuchsperiodeString
                            "
                        >
                            {{ gp.gesuchsperiodeString }}
                        </mat-option>
                    </mat-select>
                </div>
            </div>

            <!--Liste der Faelle-->
            <div class="row margin-top-30">
                <ul>
                    <li>
                        <button
                            type="button"
                            class="dv-btn btn-link btn-testfall"
                            data-test="testfall-1"
                            (click)="createTestFallType('1')"
                        >
                            <span
                                [textContent]="
                                    'Testfall Dagmar Wälti: Ledig, 1 Kind, 2 Kitas'
                                "
                            ></span>
                        </button>
                    </li>
                    <li>
                        <button
                            type="button"
                            class="dv-btn btn-link btn-testfall"
                            data-test="testfall-2"
                            (click)="createTestFallType('2')"
                        >
                            <span
                                [textContent]="
                                    'Testfall Yvonne Feutz: Verheiratet, 2 Kinder, Kita'
                                "
                            ></span>
                        </button>
                    </li>
                    <li>
                        <button
                            type="button"
                            class="dv-btn btn-link btn-testfall"
                            data-test="testfall-3"
                            (click)="createTestFallType('3')"
                        >
                            <span
                                [textContent]="
                                    'Testfall Marcia Perreira: Verheiratet, 2 Kinder, Kind 1 Kita, Kind 2 keine Betreuung'
                                "
                            ></span>
                        </button>
                    </li>
                    <li>
                        <button
                            type="button"
                            class="dv-btn btn-link btn-testfall"
                            data-test="testfall-4"
                            (click)="createTestFallType('4')"
                        >
                            <span
                                [textContent]="
                                    'Testfall Laura Walther: Verheiratet, 1 Kind, Kita'
                                "
                            ></span>
                        </button>
                    </li>
                    <li>
                        <button
                            type="button"
                            class="dv-btn btn-link btn-testfall"
                            data-test="testfall-5"
                            (click)="createTestFallType('5')"
                        >
                            <span
                                [textContent]="
                                    'Testfall Mereth Lueti: Verheiratet, 1 Kind, Kita, 2 Pensen'
                                "
                            ></span>
                        </button>
                    </li>
                    <li>
                        <button
                            type="button"
                            class="dv-btn btn-link btn-testfall"
                            data-test="testfall-6"
                            (click)="createTestFallType('6')"
                        >
                            <span
                                [textContent]="
                                    'Testfall Nora Becker: Verheiratet, 1 Kind, Kita'
                                "
                            ></span>
                        </button>
                    </li>
                    <li>
                        <button
                            type="button"
                            class="dv-btn btn-link btn-testfall"
                            data-test="testfall-7"
                            (click)="createTestFallType('7')"
                        >
                            <span
                                [textContent]="'Testfall Mereth Meier'"
                            ></span>
                        </button>
                    </li>
                    <li>
                        <button
                            type="button"
                            class="dv-btn btn-link btn-testfall"
                            data-test="testfall-8"
                            (click)="createTestFallType('8')"
                        >
                            <span
                                [textContent]="'Testfall AusInAusBern'"
                            ></span>
                        </button>
                    </li>
                    <li>
                        <button
                            type="button"
                            class="dv-btn btn-link btn-testfall"
                            data-test="testfall-9"
                            (click)="createTestFallType('9')"
                        >
                            <span [textContent]="'Testfall Abwesenheit'"></span>
                        </button>
                    </li>
                    <li>
                        <button
                            type="button"
                            class="dv-btn btn-link btn-testfall"
                            data-test="testfall-10"
                            (click)="createTestFallType('10')"
                        >
                            <span
                                [textContent]="
                                    'Testfall Umzug vor Gesuchperiode'
                                "
                            ></span>
                        </button>
                    </li>
                    <!-- TODO: When an Instiution with (Tagesschule/Ferien insel) for the 18/19 period
                    can be included in the flyway DB scripts, this test case can be enabled client side. NOTE: The
                    exisiting test UUIDS for Tagesschule and Ferien insel will also have to be updated.
                     -->
                    <!-- <li>
                        <button type="button" class="btn-link btn-testfall" (click)="createTestFallType('11')">
                            <span [textContent]="'Schulamt Only'"></span>
                        </button>
                    </li> -->
                    <li>
                        <button
                            type="button"
                            class="dv-btn btn-link btn-testfall"
                            data-test="testfall-ASIV1"
                            (click)="createTestFallType('ASIV1')"
                        >
                            <span [textContent]="'Testfall SZ 1'"></span>
                        </button>
                    </li>
                    <li>
                        <button
                            type="button"
                            class="dv-btn btn-link btn-testfall"
                            data-test="testfall-ASIV2"
                            (click)="createTestFallType('ASIV2')"
                        >
                            <span [textContent]="'Testfall SZ 2'"></span>
                        </button>
                    </li>
                    <li>
                        <button
                            type="button"
                            class="dv-btn btn-link btn-testfall"
                            data-test="testfall-ASIV3"
                            (click)="createTestFallType('ASIV3')"
                        >
                            <span [textContent]="'Testfall SZ 3'"></span>
                        </button>
                    </li>
                    <li>
                        <button
                            type="button"
                            class="dv-btn btn-link btn-testfall"
                            data-test="testfall-ASIV4"
                            (click)="createTestFallType('ASIV4')"
                        >
                            <span [textContent]="'Testfall SZ 4'"></span>
                        </button>
                    </li>
                    <li>
                        <button
                            type="button"
                            class="dv-btn btn-link btn-testfall"
                            data-test="testfall-ASIV5"
                            (click)="createTestFallType('ASIV5')"
                        >
                            <span [textContent]="'Testfall SZ 5'"></span>
                        </button>
                    </li>
                    <li>
                        <button
                            type="button"
                            class="dv-btn btn-link btn-testfall"
                            data-test="testfall-ASIV6"
                            (click)="createTestFallType('ASIV6')"
                        >
                            <span [textContent]="'Testfall ASIV 6'"></span>
                        </button>
                    </li>
                    <li>
                        <button
                            type="button"
                            class="dv-btn btn-link btn-testfall"
                            data-test="testfall-ASIV7"
                            (click)="createTestFallType('ASIV7')"
                        >
                            <span [textContent]="'Testfall ASIV 7'"></span>
                        </button>
                    </li>
                    <li>
                        <button
                            type="button"
                            class="dv-btn btn-link btn-testfall"
                            data-test="testfall-ASIV8"
                            (click)="createTestFallType('ASIV8')"
                        >
                            <span [textContent]="'Testfall ASIV 8'"></span>
                        </button>
                    </li>
                    <li>
                        <button
                            type="button"
                            class="dv-btn btn-link btn-testfall"
                            data-test="testfall-ASIV9"
                            (click)="createTestFallType('ASIV9')"
                        >
                            <span [textContent]="'Testfall ASIV 9'"></span>
                        </button>
                    </li>
                    <li>
                        <button
                            type="button"
                            class="dv-btn btn-link btn-testfall"
                            data-test="testfall-ASIV10"
                            (click)="createTestFallType('ASIV10')"
                        >
                            <span [textContent]="'Testfall ASIV 10'"></span>
                        </button>
                    </li>
                    <li *ngIf="!this.selectedBesitzer">
                        <button
                            type="button"
                            class="dv-btn btn-link btn-testfall"
                            data-test="testfall-Sozialdienst"
                            (click)="createTestFallType('Sozialdienst')"
                        >
                            <span
                                [textContent]="'Testfall Unterstützungsdienste'"
                            ></span>
                        </button>
                    </li>
                    <li *ngIf="!this.selectedBesitzer">
                        <button
                            type="button"
                            class="dv-btn btn-link btn-testfall"
                            data-test="testfall-LATS"
                            (click)="createTestFallType('LATS')"
                        >
                            <span [textContent]="'Testfall LATS'"></span>
                        </button>
                    </li>
                </ul>
            </div>

            <!-- Tutorial-Daten -->
            <h2 class="margin-top-20">Gemeindeanträge erstellen</h2>
            <div class="row">
                <div class="col-md-12">
                    <div class="row margin-top-10">
                        <div class="col-sm-3">
                            <mat-radio-group
                                name="creationType"
                                [(ngModel)]="gemeindeAntragStatus"
                                required
                            >
                                <mat-radio-button
                                    data-test="creationType.IN_BEARBEITUNG_GEMEINDE"
                                    value="IN_BEARBEITUNG_GEMEINDE"
                                    selected=""
                                    >In Bearbeitung Gemeinde
                                </mat-radio-button>
                                <mat-radio-button
                                    data-test="creationType.IN_PRUEFUNG_KANTON"
                                    value="IN_PRUEFUNG_KANTON"
                                    >In Prüfung Kanton</mat-radio-button
                                >
                            </mat-radio-group>
                        </div>
                        <div class="col-sm-2">
                            <label class="md-no-float" for="periode">
                                Antragtyp
                            </label>
                            <mat-select
                                [(ngModel)]="gemeindeAntragTyp"
                                id="gemeindeAntragTyp"
                                name="gemeindeAntragTyp"
                                class="input-testfall"
                            >
                                <mat-option
                                    *ngFor="let typ of gemeindeAntragTypeList"
                                    [value]="typ"
                                >
                                    {{ typ | translate }}
                                </mat-option>
                            </mat-select>
                        </div>
                        <div class="col-sm-2">
                            <label class="md-no-float" for="periode">
                                Gemeinde
                            </label>
                            <mat-select
                                [(ngModel)]="gemeindeForGemeindeAntrag"
                                id="gemeindeGemeindeAntrag"
                                name="gemeindeGemeindeAntrag"
                                class="input-testfall"
                            >
                                <mat-option
                                    *ngFor="let gem of gemeindeList"
                                    [value]="gem"
                                >
                                    {{ gem.name }}
                                </mat-option>
                            </mat-select>
                        </div>
                        <div class="col-sm-2">
                            <label class="md-no-float" for="periode">
                                Gesuchsperiode
                            </label>
                            <mat-select
                                [(ngModel)]="gesuchsperiodeGemeindeAntrag"
                                id="gesuchsperiodeGemeindeAntrag"
                                name="gesuchsperiodeGemeindeAntrag"
                                class="input-testfall"
                            >
                                <mat-option
                                    *ngFor="let gp of gesuchsperiodeList"
                                    [value]="gp"
                                >
                                    {{ gp.gesuchsperiodeString }}
                                </mat-option>
                            </mat-select>
                        </div>
                        <div class="col-sm-3">
                            <button
                                dvNgDebounceClick
                                (debounceClick)="
                                    createGemeindeAntragTestDaten()
                                "
                                class="dv-btn testfall-button"
                            >
                                Test-Gemeindeanträge erstellen
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <!--Gesuche eines GS loeschen-->
            <h2 class="margin-top-20">Gesuchstellerfälle löschen</h2>
            <div class="row margin-top-30">
                <div class="col-sm-7">
                    <label class="md-no-float" for="gesuchsteller">
                        Gesuchsteller dessen Fälle gelöscht werden sollen
                    </label>

                    <mat-select
                        [(ngModel)]="selectedBesitzer"
                        id="gesuchsteller"
                        name="gesuchsteller"
                        class="input-testfall"
                        data-test="gesuchsteller-faelle-loeschen"
                    >
                        <mat-option
                            *ngFor="let gp of gesuchstellerList"
                            [value]="gp"
                            [attr.data-test]="
                                getGesuchstellerDataTestValue(gp) + '-loeschen'
                            "
                        >
                            {{ gp.getFullName() }}
                        </mat-option>
                    </mat-select>
                </div>
                <div class="col-sm-4">
                    <button
                        dvNgDebounceClick
                        (debounceClick)="removeGesucheGS()"
                        class="dv-btn testfall-button"
                        data-test="delete-gesuche"
                    >
                        <span [textContent]="'DELETE' | translate"></span>
                    </button>
                </div>
            </div>

            <h2 class="margin-top-20">Gesuchsperiode inkl. Gesuche löschen</h2>
            <div class="row margin-top-30">
                <div class="col-sm-7">
                    <label class="md-no-float" for="periodeLoeschen">
                        <span>Gesuchsperiode, die gelöscht werden soll</span>
                    </label>
                    <mat-select
                        [(ngModel)]="selectedGesuchsperiode"
                        id="periodeLoeschen"
                        name="periodeLoeschen"
                        class="input-testfall"
                    >
                        <mat-option
                            *ngFor="let gpDel of gesuchsperiodeList"
                            [value]="gpDel"
                        >
                            {{ gpDel.gesuchsperiodeString }}
                        </mat-option>
                    </mat-select>
                </div>
                <div class="col-sm-4">
                    <button
                        dvNgDebounceClick
                        (debounceClick)="removeGesuchsperiode()"
                        class="dv-btn testfall-button"
                    >
                        <span [textContent]="'DELETE' | translate"></span>
                    </button>
                </div>
            </div>

            <!-- Mutieren: Heirat / Scheidung -->
            <h2 class="margin-top-20">Mutieren</h2>
            <div class="row">
                <div class="col-sm-2 dv-input-container-small">
                    <label class="md-no-float" for="dossierid"
                        >Dossier-Id:</label
                    >
                    <input
                        id="dossierid"
                        type="text"
                        name="dossierid"
                        [(ngModel)]="dossierid"
                        class="input-testfall"
                    />
                </div>
                <div class="col-sm-2 dv-input-container-small">
                    <label class="md-no-float" for="eingangsdatum"
                        >Eingangsdatum</label
                    >
                    <input
                        class="input-testfall input-testfall--datepicker"
                        type="text"
                        [matDatepicker]="dpEingangsdatum"
                        placeholder="Eingangsdatum"
                        id="eingangsdatum"
                        data-test="eingangsdatum"
                        [value]="eingangsdatum"
                    />
                    <mat-datepicker-toggle
                        matSuffix
                        [for]="dpEingangsdatum"
                    ></mat-datepicker-toggle>
                    <mat-datepicker #dpEingangsdatum></mat-datepicker>
                </div>
                <div class="col-sm-2 dv-input-container-small">
                    <label class="" for="ereignisdatum">Ereignisdatum</label>
                    <input
                        class="input-testfall input-testfall--datepicker"
                        [matDatepicker]="dpDatumHeirat"
                        type="text"
                        placeholder="Ereignisdatum"
                        id="ereignisdatum"
                        data-test="ereignisdatum"
                        [value]="ereignisdatum"
                    />
                    <mat-datepicker-toggle
                        matSuffix
                        [for]="dpDatumHeirat"
                    ></mat-datepicker-toggle>
                    <mat-datepicker #dpDatumHeirat></mat-datepicker>
                </div>
                <div class="col-sm-2 dv-input-container-small">
                    <button
                        dvNgDebounceClick
                        (debounceClick)="mutiereFallHeirat()"
                        class="dv-btn testfall-button testfall-button-duo"
                    >
                        Heirat
                    </button>
                    <button
                        dvNgDebounceClick
                        (debounceClick)="mutiereFallScheidung()"
                        class="dv-btn testfall-button testfall-button-duo"
                    >
                        Scheidung
                    </button>
                </div>
            </div>

            <!-- Test der Mailvorlagen -->
            <h2 class="margin-top-20">Mails versenden</h2>
            <div class="row">
                <div class="col-md-12 dv-input-container-small">
                    <label class="md-no-float" for="mailadresse"
                        >Mailadresse:</label
                    >
                    <input
                        id="mailadresse"
                        type="text"
                        name="mailadresse"
                        [(ngModel)]="mailadresse"
                        data-test="mailadresse"
                        class="input-testfall"
                    />
                </div>
                <div>
                    <button
                        dvNgDebounceClick
                        (debounceClick)="testAllMails()"
                        data-test="test-all-mails"
                        class="dv-btn testfall-button testfall-button"
                    >
                        Mails senden
                    </button>
                </div>
            </div>

            <!-- Tutorial-Daten -->
            <h2 class="margin-top-20" translate>TUTORIAL_TITLE</h2>
            <div class="row">
                <div class="col-md-12">
                    <button
                        dvNgDebounceClick
                        (debounceClick)="createTutorialdaten()"
                        data-test="create-tutorial-daten"
                        class="dv-btn testfall-button-duo"
                    >
                        Tutorialdaten erstellen
                    </button>
                </div>
            </div>

            <!-- KiBon Anfrage Schnittstelle Testen -->
            <div *ngIf="this.kibonAnfrageTestEnabled">
                <h2 clas="margin-top-20" translate>KIBON_ANFRAGE</h2>

                <div class="row">
                    <div class="col-sm-2 dv-input-container-small">
                        <label class="md-no-float" for="zpvNummer"
                            >zpv-Nummer:</label
                        >
                        <input
                            id="zpvNummer"
                            type="text"
                            name="zpvNummer"
                            [(ngModel)]="zpvNummer"
                            class="input-testfall"
                        />
                    </div>
                    <div class="col-sm-2 dv-input-container-small">
                        <label class="md-no-float" for="antragId"
                            >Antrag-Id:</label
                        >
                        <input
                            id="antragId"
                            type="text"
                            name="antragId"
                            [(ngModel)]="antragId"
                            class="input-testfall"
                        />
                    </div>
                    <div class="col-sm-2 dv-input-container-small">
                        <label
                            class="md-no-float"
                            for="gesuchsperiodeBeginnJahr"
                            >gesuchsperiodeBeginnJahr:</label
                        >
                        <input
                            id="gesuchsperiodeBeginnJahr"
                            type="text"
                            name="gesuchsperiodeBeginnJahr"
                            [(ngModel)]="gesuchsperiodeBeginnJahr"
                            class="input-testfall"
                        />
                    </div>
                    <div class="col-sm-2 dv-input-container-small">
                        <label
                            class="required"
                            class="md-no-float"
                            translate
                            for="begu_start_ID"
                            >GEBURTSDATUM</label
                        >
                        <input
                            class="dv-mat-datepicker"
                            type="text"
                            name="geburtsdatum_name"
                            id="begu_start_ID"
                            [(ngModel)]="geburtsdatum"
                            [matDatepicker]="geburtsdatum_name"
                            placeholder="{{
                                'DATE_PLACEHOLDER_FIRSTOFMONTH' | translate
                            }}"
                            required
                        />
                        <mat-datepicker-toggle
                            matSuffix
                            [for]="geburtsdatum_name"
                        ></mat-datepicker-toggle>
                        <mat-datepicker #geburtsdatum_name></mat-datepicker>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <button
                            dvNgDebounceClick
                            (debounceClick)="testKibonAnfrageResponse()"
                            class="dv-btn testfall-button-duo"
                        >
                            KiBon Anfrage Schnittstelle testen
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
