<!--
  ~ Copyright (C) 2022 DV Bern AG, Switzerland
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as
  ~ published by the Free Software Foundation, either version 3 of the
  ~ License, or (at your option) any later version.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. If not, see <https://www.gnu.org/licenses/>.
  -->

<div>
    <label
        *ngIf="label"
        [for]="uniqueName + '_id'"
        class="ebegu-h1"
        [class.required]="required"
    >
        <span>{{ label | translate }}</span>
        <dv-tooltip
            *ngIf="tooltip"
            [text]="tooltip | translate"
            [inputId]="uniqueName + '_id'"
        ></dv-tooltip>
    </label>
    <span *ngIf="inlineHint" class="inline-hint">{{
        inlineHint | translate
    }}</span>
    <mat-radio-group
        (change)="change($event)"
        [(ngModel)]="model"
        [name]="uniqueName"
        [id]="uniqueName + '_id'"
        #radio="ngModel"
        [disabled]="readonly"
        [required]="required"
    >
        <mat-radio-button [value]="true" data-test="radio-value.ja">{{
            labelYes | translate
        }}</mat-radio-button>
        <mat-radio-button [value]="false" data-test="radio-value.nein">{{
            labelNo | translate
        }}</mat-radio-button>
    </mat-radio-group>
    <dv-bisher-x
        [korrektur]="model"
        [deklaration]="dvBisherValue"
        [showBisher]="showBisher()"
    ></dv-bisher-x>
    <dv-error-messages
        *ngIf="form?.submitted"
        [errorObject]="radio.errors"
        [inputId]="uniqueName + '_id'"
    >
    </dv-error-messages>
</div>
