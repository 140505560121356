<!--
  ~ Copyright (C) 2019 DV Bern AG, Switzerland
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as
  ~ published by the Free Software Foundation, either version 3 of the
  ~ License, or (at your option) any later version.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program.  If not, see <https://www.gnu.org/licenses/>.
  -->
<div style="display: inline-block; max-width: 100%">
    <div class="onboarding-welcome">
        @if (isZpvNummerErfolgreichVerknuepft) {
            <p translate style="margin-bottom: 20px">ZPV_GS2_SUCCESS</p>
        } @else {
            <p translate class="well well-status-abgewiesen">
                ZPV_GS_NICHT_VERKNUEPFT
            </p>
        }

        <p translate>ZPV_LOGOUT</p>
    </div>

    <div class="onboarding-login">
        <div class="onboarding-login-buttons">
            <a href="https://www.be.ch/belogin">
                <button
                    class="dv-btn onboarding-red-border-button onboarding-login-button-width"
                >
                    <span translate>ZPV_ZUM_BE_LOGIN</span>
                </button>
            </a>
        </div>
    </div>
</div>
