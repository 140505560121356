<!--
  ~ Copyright (C) 2023 DV Bern AG, Switzerland
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as
  ~ published by the Free Software Foundation, either version 3 of the
  ~ License, or (at your option) any later version.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. If not, see <https://www.gnu.org/licenses/>.
  -->
<div class="onboarding-container" *ngIf="splittedScreen">
    <div class="col-md-6 mobile-hide">
        <div class="onboarding-container-left">
            <div class="onboarding-title">
                <div
                    class="kibon-logo-white"
                    [style.background-image]="logoFileNameWhite$ | async"
                ></div>
                <p translate>ONBOARDING_SUBTITLE</p>
                <div class="onboarding-information">
                    <div class="information-icons">
                        <i class="fa fa-globe"></i>
                    </div>
                    <div class="information-text">
                        <h4 [innerHTML]="description1 | translate"></h4>
                    </div>
                </div>
                <div
                    class="onboarding-information"
                    *ngIf="description2 | translate"
                >
                    <div class="information-icons">
                        <i class="fa fa-home"></i>
                    </div>
                    <div>
                        <h4 [innerHTML]="description2 | translate"></h4>
                    </div>
                </div>
                <div class="onboarding-information">
                    <div class="information-icons">
                        <i class="fa fa-cogs"></i>
                    </div>
                    <div>
                        <h4 [innerHTML]="description3 | translate"></h4>
                    </div>
                </div>
                <div class="onboarding-information">
                    <div class="information-icons">
                        <i class="fa fa-file"></i>
                    </div>
                    <h4 [innerHTML]="description4 | translate"></h4>
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-6">
        <div class="onboarding-container-right">
            <ui-view></ui-view>
        </div>
    </div>
</div>
<div class="onboarding-single-container" *ngIf="!splittedScreen">
    <div class="container">
        <div class="onboarding-title">
            <div
                class="kibon-logo-white"
                [style.background-image]="logoFileNameWhite$ | async"
            ></div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <ui-view></ui-view>
            </div>
        </div>
    </div>
</div>
