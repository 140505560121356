<!--
  ~ Ki-Tax: System for the management of external childcare subsidies
  ~ Copyright (C) 2018 City of Bern Switzerland
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as
  ~ published by the Free Software Foundation, either version 3 of the
  ~ License, or (at your option) any later version.
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. If not, see <http://www.gnu.org/licenses/>.
  -->

<div class="container">
    <h2>Testuser</h2>

    <table class="locallogin-table">
        @for (entry of groupByGroup(users$ | async); track entry[0]) {
            <tbody>
                <th class="locallogin-table-title" colspan="4">
                    {{ entry[0] | titlecase }}
                </th>
                @for (user of sortUsersByRole(entry[1]); track user.email) {
                    <tr class="locallogin-table-row">
                        <td class="role locallogin-table-cell">
                            {{ user.role }}
                        </td>
                        <td class="info locallogin-table-cell">
                            {{ user.info }}
                        </td>
                        <td class="name locallogin-table-cell">
                            {{ user.vorname }} {{ user.name }}
                        </td>
                        <td class="button locallogin-table-cell">
                            <button
                                class="dv-btn"
                                [attr.data-test]="
                                    'test-user-' +
                                    user.vorname +
                                    '-' +
                                    user.name
                                "
                                (click)="logIn(user.email)"
                            >
                                Einloggen
                            </button>
                        </td>
                    </tr>
                } @empty {
                    <tr class="locallogin-table-row">
                        <td class="locallogin-table-cell">
                            Noch keine Testuser, Button unten klicken
                        </td>
                    </tr>
                }
            </tbody>
        }
    </table>

    <hr />

    <section>
        <button (click)="createUser()" data-test="create-user">
            User erstellen
        </button>
        @if (createUserResponse$; as response) {
            <pre>{{ response | async | json }}</pre>
        }
    </section>
</div>
