<div class="row">
    <div class="col-xs-12 dv-input-container-question">
        <div class="form-group">
            <span class="ebegu-h1 required">{{
                'MOMENTAN_SELBSTSTAENDIG' | translate
            }}</span>
            <dv-radio-container-x>
                <dv-radio-input-x
                    [(model)]="
                        model.finanzielleSituationJA.momentanSelbststaendig
                    "
                    [dvBisherValue]="!isGesuchReadonly()"
                    [required]="true"
                >
                </dv-radio-input-x>
            </dv-radio-container-x>
        </div>
    </div>
</div>
<div *ngIf="showVeranlagungErhalten()" class="row">
    <div class="col-xs-12 dv-input-container-question">
        <div class="form-group">
            <span
                *ngIf="
                    model.finanzielleSituationJA.momentanSelbststaendig ===
                    false
                "
                class="ebegu-h1 required"
                >{{
                    'FINANZIELLE_SITUATION_STEUERVERANLAGUNG'
                        | translate
                            : {
                                  basisjahr: gesuchModelManager.getBasisjahr(),
                                  basisjahrMinus:
                                      gesuchModelManager.getBasisjahrMinus(1),
                                  basisjahrMinus2:
                                      gesuchModelManager.getBasisjahrMinus(2)
                              }
                }}</span
            ><span
                *ngIf="
                    model.finanzielleSituationJA.momentanSelbststaendig === true
                "
                class="ebegu-h1 required"
                >{{
                    'FINANZIELLE_SITUATION_STEUERVERANLAGUNG_SELBSTSTAENDIG'
                        | translate
                            : {
                                  basisjahr: gesuchModelManager.getBasisjahr(),
                                  basisjahrMinus:
                                      gesuchModelManager.getBasisjahrMinus(1),
                                  basisjahrMinus2:
                                      gesuchModelManager.getBasisjahrMinus(2)
                              }
                }}</span
            >
            <dv-input-container-x [verticalAlign]="true">
                <dv-radio-container-x>
                    <dv-radio-input-x
                        [(model)]="
                            model.finanzielleSituationJA
                                .steuerveranlagungErhalten
                        "
                        (modelChange)="setSteuerveranlagungErhalten($event)"
                        [dvBisherValue]="!isGesuchReadonly()"
                        labelNo="LABEL_NICHT_ERHALTEN_ODER_QUELLENBESTEUERT"
                        [required]="true"
                    >
                    </dv-radio-input-x>
                </dv-radio-container-x>
            </dv-input-container-x>
        </div>
    </div>
</div>
